; (function ($) {
  // Add posibility to scroll to selected option
  // usefull for select for example
  $.fn.scrollTo = function (elem) {
    $(this).scrollTop(
      $(this).scrollTop() - $(this).offset().top + $(elem).offset().top
    )
    return this
  }

  $.fn.dropdown = function (options) {
    var defaults = {
      inDuration: 300,
      outDuration: 225,
      constrain_width: true, // Constrains width of dropdown to the activator
      hover: true,
      gutter: 0, // Spacing from edge
      belowOrigin: false,
    }

    options = $.extend(defaults, options)
    this.each(function () {
      var origin = $(this)

      // Dropdown menu
      var activates = $('#' + origin.attr('data-activates'))

      function updateOptions() {
        if (origin.data('induration') != undefined) {
          options.inDuration = origin.data('inDuration')
        }
        if (origin.data('outduration') != undefined) {
          options.outDuration = origin.data('outDuration')
        }
        if (origin.data('constrainwidth') != undefined) {
          options.constrain_width = origin.data('constrainwidth')
        }
        if (origin.data('hover') != undefined) {
          options.hover = origin.data('hover')
        }
        if (origin.data('gutter') != undefined) {
          options.gutter = origin.data('gutter')
        }
        if (origin.data('beloworigin') != undefined) {
          options.belowOrigin = origin.data('beloworigin')
        }
      }

      updateOptions()

      // Move Dropdown menu to body. This allows for absolute positioning to work
      if (!activates.parent().is($('body'))) {
        activates.detach()
        $('body').append(activates)
      }

      var dropdownRealHeight = activates.height()

      /*
      Helper function to position and resize dropdown.
      Used in hover and click handler.
    */
      function placeDropdown() {
        // Check html data attributes
        updateOptions()

        if (options.constrain_width == true) {
          activates.css('width', origin.outerWidth())
        }
        var offset = 0
        if (options.belowOrigin == true) {
          offset = origin.height()
        }

        // Handle edge alignment
        var offsetLeft = origin.offset().left

        var width_difference = 0
        var gutter_spacing = options.gutter

        if (offsetLeft + activates.innerWidth() > $(window).width()) {
          width_difference = origin.innerWidth() - activates.innerWidth()
          gutter_spacing = gutter_spacing * -1
        }
        if (elementOrParentIsFixed(origin[0])) {
          activates.css({
            display: 'block',
            position: 'fixed',
            height: 0,
            top: origin.offset().top - $(window).scrollTop() + offset,
            left: origin.offset().left + width_difference + gutter_spacing,
          })
        } else {
          activates.css({
            display: 'block',
            top: origin.offset().top + offset,
            left: origin.offset().left + width_difference + gutter_spacing,
            height: 0,
          })
        }
        activates
          .animate(
            { opacity: 1 },
            {
              duration: options.inDuration,
              queue: false,
            }
          )
          .animate(
            {
              height: dropdownRealHeight,
            },
            {
              duration: options.inDuration,
              queue: false,
              complete: function () {
                activates.css('overflow-y', 'auto')
              },
            }
          )
      }
      function elementOrParentIsFixed(element) {
        var $element = $(element)
        var $checkElements = $element.add($element.parents())
        var isFixed = false
        $checkElements.each(function () {
          if ($(this).css('position') === 'fixed') {
            isFixed = true
            return false
          }
        })
        return isFixed
      }

      function hideDropdown() {
        activates.animate(
          {
            opacity: 0,
          },
          {
            duration: options.outDuration,
            complete: function () {
              activates.css({
                display: 'none',
                'overflow-y': '',
              })
            },
          }
        )
      }

      // Hover
      if (options.hover) {
        // Hover handler to show dropdown
        origin.on('mouseover', function () {
          // Mouse over
          placeDropdown()
        })

        // Document click handler
        activates.on('mouseleave', function () {
          // Mouse out
          hideDropdown()
        })

        // Click
      } else {
        // Click handler to show dropdown
        origin.unbind('click.' + origin.attr('id'))
        origin.bind('click.' + origin.attr('id'), function (e) {
          if (origin[0] == e.currentTarget) {
            e.preventDefault() // Prevents button click from moving window
            placeDropdown()
          }

          $(document).bind('click.' + activates.attr('id'), function (e) {
            if (
              !activates.is(e.target) &&
              !origin.is(e.target) &&
              !origin.find(e.target).length > 0
            ) {
              hideDropdown()
              $(document).unbind('click.' + activates.attr('id'))
            }
          })
        })
      } // End else

      // Listen to open and close event - useful for select component
      origin.on('open', placeDropdown)
      origin.on('close', hideDropdown)
    })
  } // End dropdown plugin
})(jQuery)
