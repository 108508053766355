import '@/legacy/stylesheets/application.css.scss'

import '@/legacy/javascripts/vendor/jquery'

import '@/legacy/javascripts/vendor/jquery.prevent-double-submission'
import '@/legacy/javascripts/vendor/datepicker'
import '@/legacy/javascripts/vendor/dropdown'
import '@/legacy/javascripts/vendor/forms'
import '@/legacy/javascripts/vendor/leanModal'
import '@/legacy/javascripts/vendor/toasts'

import '@/legacy/javascripts/denteo.common'
import '@/legacy/javascripts/denteo.patients'
import '@/legacy/javascripts/denteo.init'

// try to instantiate call a js method corresponding to the current controller
// (class) and the current action (method)
;(function ($) {
  $(document).on('turbolinks:load', function () {
    var $body = $('body')
    var controller = ($body.data('controller') || '').replace(/\//g, '_')
    var action = $body.data('action')

    var activeController = window.Denteo[controller]
    if (activeController !== undefined) {
      if ($.isFunction(activeController.init)) {
        activeController.init()
      }

      if ($.isFunction(activeController[action])) {
        activeController[action]()
      }
    }
  })
})(jQuery)

$(document).on('turbolinks:load', function () {
  window.DenteoInit.initDatePicker()
  window.DenteoInit.activateInputs()
  window.DenteoInit.initSelects()
  window.DenteoInit.fucusInputs()

  $('.autosubmit').on('change', function (e) {
    var form = e.target.form
    // Rails.fire(form, 'submit') # As soon as we switch to rails_ujs from jquery_ujs
    $(form).trigger('submit.rails')
  })
})

// TODO: Check if in versions after Turbolinks 5.2 this is still
// needed. Prevents Turbolinks from visiting links starting with an '#'
$(document).on('turbolinks:click', function (event) {
  if (event.target.getAttribute('href').charAt(0) === '#') {
    event.preventDefault()
  }
})
