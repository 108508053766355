function toast(message, displayLength, className, completeCallback) {
  className = className || ''
  var container
  if ($('#toast-container').length == 0) {
    // create notification container
    container = $('<div></div>')
      .attr('id', 'toast-container')
      .attr('class', 'ui-layout--mat')
    $('body').append(container)
  }

  // Select and append toast
  container = $('#toast-container')
  var newToast = createToast(message)
  container.append(newToast)

  newToast.css({ top: parseFloat(newToast.css('top')) + 35 + 'px', opacity: 0 })
  newToast.animate({ top: '0px', opacity: 1 }, { duration: 300, queue: false })

  var timeLeft = displayLength
  var counterInterval = setInterval(function () {
    if (newToast.parent().length === 0) window.clearInterval(counterInterval)

    if (!newToast.hasClass('panning')) {
      timeLeft -= 100
    }

    if (timeLeft <= 0) {
      newToast.animate(
        { opacity: 0, marginTop: '-40px' },
        {
          duration: 375,
          queue: false,
          complete: function () {
            if (typeof completeCallback === 'function') completeCallback()
            $(this).remove()
          },
        }
      )
      window.clearInterval(counterInterval)
    }
  }, 100)

  function createToast(html) {
    var toast = $("<div class='toast'></div>").addClass(className).html(html)

    toast.animate(
      { left: 0, opacity: 1 },
      { marginTop: '-40px' },
      {
        duration: 375,
        queue: false,
        complete: function () {
          if (typeof completeCallback === 'function') completeCallback()
          toast.remove()
        },
      }
    )

    return toast
  }
}

window.toast = toast
