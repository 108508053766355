// This is the transpiled output from the coffee-rails gem
;(function () {
  window.DenteoInit = (function () {
    function DenteoInit() {}

    DenteoInit.initSelects = function () {
      var activateLabel, copyInvalidClass
      $('select').material_select()
      activateLabel = function (item) {
        if ($(item).val()) {
          return $(item).parent().siblings('label').addClass('active')
        }
      }
      copyInvalidClass = function (item) {
        return $(item).siblings('input.select-dropdown').addClass('invalid')
      }
      $('select').on('change', function () {
        return activateLabel(this)
      })
      return setTimeout(function () {
        $('select').each(function (index, item) {
          return activateLabel(item)
        })
        return $('select.invalid').each(function (index, item) {
          return copyInvalidClass(item)
        })
      }, 200)
    }

    DenteoInit.activateInputs = function () {
      return $('input, textarea').each(function (index, item) {
        if ($(item).val()) {
          return $(item).siblings('label').addClass('active')
        }
      })
    }

    DenteoInit.initDatePicker = function () {
      return $('input[type=date], input.date').pickadate({
        format: 'dd.mm.yyyy',
        selectYears: 200,
        selectMonths: true,
        hiddenSuffix: '',
      })
    }

    DenteoInit.fucusInputs = function () {
      var input
      input = $('input[autofocus]:first')
      input.focus()
      input.val(input.val())
      return input.trigger('change')
    }

    return DenteoInit
  })()
}.call(window))
