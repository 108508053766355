// This is the transpiled output from the coffee-rails gem
;(function () {
  var UxHelper

  if (window.Denteo == null) {
    window.Denteo = {}
  }

  $(document).on('turbolinks:load', function () {
    return new UxHelper()
  })

  UxHelper = (function () {
    function UxHelper() {
      this.mouseOverLinks()
      $('form').preventDoubleSubmission()
    }

    UxHelper.prototype.mouseOverLinks = function () {
      var links
      links = $('.mouse_over_links li[href], .mouse_over_links tr[href]')
      links.off('click')
      return links.on('click', function (event) {
        if (
          event.target.nodeName === 'A' ||
          $(event.target).parents('.actions').length ||
          $(event.target).parents('a').length
        ) {
          return
        }
        event.preventDefault()
        return (window.location.href = $(this).attr('href'))
      })
    }

    return UxHelper
  })()
}.call(window))
