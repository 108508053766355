// This is the transpiled output from the coffee-rails gem
; (function () {
  var PatientsController

  PatientsController = (function () {
    function PatientsController() { }

    PatientsController.prototype.show = function () {
      $('.credit-worthys-toggle-step-two').on('click', function () {
        $('.credit-worthys-step-one').toggleClass('hidden', true)
        return $('.credit-worthys-step-two').toggleClass('hidden', false)
      })
      $('.credit-worthys-toggle-step-one').on('click', function () {
        $('.credit-worthys-step-one').toggleClass('hidden', false)
        return $('.credit-worthys-step-two').toggleClass('hidden', true)
      })
      $('#mf_health_credit_worthy_reserved_amount').on('input', function () {
        var amount, credit
        credit =
          $('#mf_health_credit_worthy_reserved_amount').data().credit || 0
        amount =
          parseInt(
            $('#mf_health_credit_worthy_reserved_amount').prop('value')
          ) || 0
        $('#reserve-credit-button').toggleClass(
          'btn-disabled',
          amount < 1 || amount > credit
        )
        $('#reserve-credit-button').toggleClass(
          'btn',
          amount > 0 && amount <= credit
        )
        $('#reserve-credit-button').prop(
          'disabled',
          amount < 1 || amount > credit
        )
        $('#amount-too-high-roman').toggleClass('hidden', amount <= credit)
        $('#mf_health_credit_worthy_reserved_amount').toggleClass(
          'invalid',
          amount < 1 || amount > credit
        )
        return $('#mf_health_credit_worthy_reserved_amount').toggleClass(
          'valid',
          amount > 0 && amount <= credit
        )
      })
      return $('#remarks-textarea').on('blur', function () {
        return $('#remarks-form').submit()
      })
    }

    return PatientsController
  })()

  window.Denteo.patients = new PatientsController()
}.call(window))
